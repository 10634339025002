<template>
  <div class="c-calendar">
    <div class="c-calendar__head">
      <div class="c-calendar__row">
        <div class="c-calendar__cell sunday">日</div>
        <div class="c-calendar__cell">月</div>
        <div class="c-calendar__cell">火</div>
        <div class="c-calendar__cell">水</div>
        <div class="c-calendar__cell">木</div>
        <div class="c-calendar__cell">金</div>
        <div class="c-calendar__cell saturday">土</div>
      </div>
    </div>
    <div class="c-calendar__main__short">
      <div class="c-calendar__body">
        <div
          v-for="(week, index) in calendars"
          :key="index"
          class="c-calendar__row"
        >
          <div
            v-for="(day, cellIndex) in week"
            :key="cellIndex"
            class="c-calendar__cell"
            :class="{
              today: isSameDate(day.full, today),
              notThisMonth: day.month != currentDate.get('month') + 1,
            }"
          >
            <div class="c-calendar__cell__date">
              {{ day.date == 1 ? day.month + "/" + day.date : day.date }}
            </div>
            <div class="c-calendar__cell__events">
              <template v-if="reserves[day.fullStr]">
                <template
                  v-for="(reserve, reserveIndex) in getShowedReserves(
                    reserves[day.fullStr]
                  )"
                >
                  <ReserveItem
                    :key="reserveIndex"
                    v-if="reserveIndex < maxReserveLen"
                    :reserve="reserve"
                    :useStoreList="useStoreList"
                  />
                </template>
                <div
                  v-if="
                    getShowedReserves(reserves[day.fullStr]).length >
                    maxReserveLen
                  "
                  class="c-calendar__reserve other"
                >
                  他{{
                    getShowedReserves(reserves[day.fullStr]).length -
                    maxReserveLen
                  }}件
                </div>
              </template>
            </div>
            <!-- /__events -->
            <ReservePopup :cellIndex="cellIndex" :day="day" :isMonth="true">
              <div v-if="reserves[day.fullStr]" class="c-calendar__popup__body">
                <template
                  v-for="(reserve, puReserveIndex) in getShowedReserves(reserves[day.fullStr])"
                >
                  <ReserveItem
                    :key="puReserveIndex"
                    :reserve="reserve"
                    :useStoreList="useStoreList"
                    isReversePopup="true"
                    v-show="
                      selectedStoreList.indexOf(reserve.generateStoreId) >= 0
                    "
                    @showDetail="reserveClick(reserve.manifestId)"
                  />
                </template>
              </div>
            </ReservePopup>
          </div>
          <!-- /__cell -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ReserveItem from "./ReserveItem";
import ReservePopup from "./ReservePopup";
import generateCalendar from "./mixins/generateCalendar";
import timeManage from "./mixins/timeManage";
import sortFunction from "./mixins/sortFunction";

export default {
  components: {
    ReserveItem,
    ReservePopup,
  },
  mixins: [generateCalendar, timeManage, sortFunction],
  props: {
    reserves: {
      type: Object,
    },
    currentDate: {
      type: Object,
    },
    reserveClick: {
      type: Function,
    },
    useStoreList: {
      type: Array,
    },
    selectedStoreList: {
      type: Array,
    },
  },
  data() {
    return {
      today: moment(),
      maxReserveLen: 3,
    };
  },
  methods: {
    isSameDate(date1, date2) {
      return (
        moment(date1).isSame(date2, "year") &&
        moment(date1).isSame(date2, "month") &&
        moment(date1).isSame(date2, "day")
      );
    },
  },

  mounted() {},
};
</script>
